html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  color: black;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #eee;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

::-webkit-scrollbar-track {
  background-color: #eee;
  border-radius: 10px;
}

body {
  background-color: #f1f3f8;
  padding: 25px 0px;
}

@font-face {
  font-family: "WantedSans-ExtraBold";
  src: url("../fonts/WantedSans-ExtraBold.woff2") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "WantedSans-Medium";
  src: url("../fonts/WantedSans-Medium.woff2") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "WantedSans-SemiBold";
  src: url("../fonts/WantedSans-SemiBold.woff2") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "WantedSans-Bold";
  src: url("../fonts/WantedSans-Bold.woff2") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "WantedSans-Regular";
  src: url("../fonts/WantedSans-Regular.woff2") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "GmarketSans-Bold";
  src: url("../fonts/GmarketSansTTFBold.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "GmarketSans-Medium";
  src: url("../fonts/GmarketSansTTFMedium.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

.wrapper {
  width: 1680px;
  margin: 0 auto;
}

.boxShadowOrg  {
  background-color: white;
  box-shadow: 0px 5px 9px 0px rgba(51, 102, 153, 0.06);
  border-radius: 15px;
}

.boxSpace {
  margin-bottom: 15px;
}

.aside {
  max-width: 337px;
}

.asideHeader {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.asideGrade {
  height: 192px;
}

.asideGradeHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.asideGradeHeader img {
  margin-right: 10px;
}

.asideGradeHeader p {
  font-family: "WantedSans-Medium";

  font-size: 20px;
}
.asideGradeHeader strong {
  font-family: "WantedSans-ExtraBold";
}

.asideGradeContents {
  display: flex;
  justify-content: center;
  margin-top: 17px;
  margin-bottom: 25px;
  height: 100%;
}

.asideGradeContents div {
  width: 140px;
  background-image: url(../images/bg-box.png);
  background-repeat: no-repeat;
  padding: 15px 0px;
  text-align: center;
}

.asideGradeContents div:first-child {
  margin-right: 15px;
}

.asideGradeContents div p:first-child {
  margin-bottom: 8px;
}

.asideGradeTitle {
  margin-top: 6px;
  margin-bottom: 8px;
  font-family: "GmarketSans-Medium";
  font-size: 15px;
  font-weight: bold;
}

.asideGradeText {
  font-family: "GmarketSans-Bold";
  font-size: 16px;
  color: #00ccff;
  font-weight: bold;
}

.asideNav {
  padding: 28px;
}

.asideNavTitleBoxOrg {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
}

.asideNavTitleBoxOrg img {
  margin-right: 10px;
}

.asideNavTitleBoxOrg p {
  font-family: "WantedSans-SemiBold";
  font-size: 21px;
}

.boxShadow  {
  font-family: "WantedSans-Regular";
  font-size: 16px;
  color: #ccccff;
  display: flex;
  align-items: center;
}

.asideNavLinkBox a {
  border: 1px solid #ccccff;
  border-radius: 5px;
  width: 240px;
  display: block;
  margin-left: 10px;
  text-align: center;
  padding: 10px 0px;
}

.asideNavLinkBox a.selected-nav {
  border: 1px solid #33cc00;
}

.asideNav li .asideNavLinkBox:not(:last-child) {
  margin-bottom: 8px;
}

.asideNav li:not(:last-child) {
  margin-bottom: 25px;
}

.index {
  display: grid;
  grid-template-columns: 1fr 2.5fr 1fr;
  gap: 15px;
}

.subPage {
  display: grid;
  grid-template-columns: 0.8fr 3fr;
  gap: 15px;
}

.selectedNav {
  color: #33cc00;
}

.gradientWrapper {
  display: flex;
  justify-content: flex-end;
}

.gradientWrapper button {
  width: 138px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0px;
  color: white;
  font-family: "WantedSans-Medium";
  font-size: 16px;
  border: none;
  border-radius: 8px;
  background: linear-gradient(to right, #cc3399, #3399ff);
  cursor: pointer;
}

.gradientWrapper button span {
  margin-left: 8px;
}

.searchForm {
  background-color: rgb(29, 38, 55);
  padding: 25px 0px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}

.searchForm select {
  height: 45px;
  width: 186px;
  border-radius: 8px;
  padding: 0px 15px;
  outline: none;
  font-family: "WantedSans-Regular";
  font-size: 16px;
  margin-right: 8px;
  border: 1px solid #d9d9d9;
  appearance: none;
  background-color: white;
  background-image: url("../images/chevron-down.png");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px;
}
.searchForm input {
  padding: 0px 15px;
  width: 227px;
  height: 45px;
  border-radius: 8px;
  border: none;
  font-family: "WantedSans-Regular";
  font-size: 16px;
  margin-right: 8px;
}

.searchForm input[name="urls"] {
  width: 450px;
}

.flexible {
  display: flex;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paginationPage {
  font-family: "WantedSans-Regular";
  font-size: 15px;
  width: 35px;
  height: 35px;
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paginationPage:not(:last-child) {
  margin-right: 15px;
}

.paginationPage.selectedPage {
  font-family: "WantedSans-Bold";
  background-color: black;
  color: white;
  border-radius: 100%;
}

.asterisk {
  color: #cc0066;
}

.mTrendsAlert {
  display: none;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.mTrendsAlert img {
  width: 15px;
  height: 15px;
  margin-right: 8px;
}

.mTrendsAlert span {
  font-family: "WantedSans-Regular";
  font-size: 13px;
  color: #999999;
}

.mLogo {
  display: none;
  justify-content: center;
  margin-bottom: 21px;
}

main {
  position: relative;
}

.mFooter {
  display: none;
}

.mHeader {
  display: none;
}

.mWarning {
  display: none;
}

.loginWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loginWrapper h3 {
  font-size: 22px;
  font-family: "WantedSans-SemiBold";
  display: flex;
  align-items: center;
}

.loginWrapper h3 img {
  margin-left: 10px;
}
.mWarning {
  display: none;
}
.menuContent .gradientWrapper button {
  width: 100%;
  font-size: 15px;
}
.menuContent .gradientWrapper {
  margin: 15px 0px;
}

.menuContent .asideNavTitleBox p {
  font-size: 17px;
}

.menuContent .asideNavTitleBox img {
  width: 17px;
}

.menuContent .asideNavLinkBoxOrg {
  width: 100%;
  border: none;
  text-align: left;
  font-family: "WantedSans-SemiBold";
  padding: 4px 0px;
}

.menuContent .asideNav li .asideNavLinkBoxOrg:not(:last-child) {
  margin-bottom: 5px;
}

.menuContent .asideNavLinkBoxOrg {
  font-size: 15px;
}

.menuContent .asideNav {
  padding: 20px;
}

.menuContent .asideNav ul li:not(:last-child) {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 15px;
}

.menuContent .loginWrapper h3 {
  font-size: 18px;
  font-family: "WantedSans-Medium";
}

.menuContent .loginWrapper h3 img:first-child {
  margin-right: 8px;
}

.menuContent .loginWrapper h3 strong {
  font-family: "WantedSans-ExtraBold";
}

.menuContent .gradientWrapper .btnCol {
  display: flex;
}

.menuContent .gradientWrapper .startBtn {
  display: flex;
  justify-content: space-between;

  padding: 20px 25px;
}

.menuContent .gradientWrapper .btnCol p:first-child {
  /* font-family: "WantedSans-SemiBold";
  font-size: 14px; */
  margin-right: 10px;
}

.menuContent .gradientWrapper .btnCol p:last-child {
  font-weight: bold;
}

.menuContent .gradientWrapper .btnCol span {
  font-weight: 500;
}

.menuContent .gradientWrapper .btnCol:nth-child(2) {
  color: rgba(153, 153, 153, 0.5);
}

.menuContent .gradientWrapper button span {
  margin-left: 0px;
}

@keyframes slideIn {
  from {
    left: -100%;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    left: 0;
    opacity: 1;
  }
  to {
    left: -100%;
    opacity: 0;
  }
}

.mHamburger {
  display: none;
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #f1f3f8;
  z-index: 1000;
  padding: 25px;
  overflow-y: scroll;
  padding-bottom: 100px;
  opacity: 0;
  transition: all 0.3s ease-in-out;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.mHamburger.slide-in {
  display: block;
  left: 0;
  opacity: 1;
}

.mHamburger.slide-out {
  display: block;
  left: -100%;
  opacity: 0;
}

.mHamburger .mFooter {
  z-index: 1001;
  position: static;
}
.noScroll {
  overflow: hidden;
}

.mcwt1Row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
}

.mcwt1Row:first-child {
  border-top: 1px solid #d9d9d9;
}

.mcwtCaption {
  font-family: "WantedSans-Medium";
  font-size: 14px;
  background-color: rgb(241, 243, 248);
  padding: 20px 0px;
  text-align: center;
  width: 120px;
  border-right: 1px solid #d9d9d9;
}

.mcwtContent {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  padding: 0px 15px;
  width: 70%;
}
.mcwtContent input {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  font-size: 13px;
  height: 30px;
}

.mcwtContent select {
  border-radius: 8px;
  padding: 0px 15px;
  outline: none;
  font-family: "WantedSans-Regular";
  font-size: 13px;
  height: 30px;
  border: 1px solid #d9d9d9;
  appearance: none;
  background-color: white;
  background-image: url("../images/chevron-down.png");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px;
  margin: 0 auto;
  width: 100%;
}

.mCrawlingTable {
  display: none;
  margin-bottom: 30px;
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 150000px; /* 텍스트의 최대 폭을 제한 */
  vertical-align: top;
}

.tooltip:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
}

.pagination-btn {
  background-color: #f4f4f4;
  color: #333;
  border: 1px solid #ddd;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pagination-btn:hover {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.pagination-btn:disabled {
  background-color: #e9ecef;
  color: #6c757d;
  border-color: #ddd;
  cursor: not-allowed;
}

.pagination-btn.active {
  background-color: #007bff;
  color: white;
  border-color: #0056b3;
}

.pagination-dots {
  color: #333;
  padding: 8px;
}

