.crawlingBox {
  padding: 50px;
}

.subPage {
  display: grid;
  grid-template-columns: 0.7fr 1.3fr;
}


.crawlingBox .gradientWrapper button span {
  margin-left: 0px;
  font-family: "WantedSans-Regular";
  font-size: 18px;
}

.highlightBox {
  background-color: rgb(243, 248, 253);
  border: 1px solid rgb(150, 200, 243);
  border-top-left-radius: 15px;
  padding: 20px;
  margin-bottom: 36px;
}

.highlightBox ul li {
  font-family: "WantedSans-Medium";
  font-size: 17px;
  color: #6699cc;
}

.highlightBox ul li:not(:last-child) {
  margin-bottom: 8px;
}

.crawlingTable {
  width: 100%;
  margin-bottom: 25px;
}

.crawlingTable thead {
  font-family: "WantedSans-Medium";
  font-size: 17px;
  background-color: rgb(241, 243, 248);
}

.crawlingTable thead th {
  border-right: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  padding: 13px 0px;
}

.crawlingTable thead th:last-child {
  border-right: 0px;
}

.crawlingTable tbody {
  text-align: center;
}

.crawlingTable tbody * {
  font-family: "WantedSans-Regular";
  font-size: 16px;
  color: #333333;
}

.crawlingTable tbody td {
  padding: 13px 20px;
  border-right: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}

.crawlingTable tbody td:last-child {
  border-right: 0px;
}

.crawlingTable tbody select {
  outline: none;
  border: 1px solid #d9d9d9;
  padding: 12px;
  width: 120px;
  border-radius: 7px;
}

.crawlingTable tbody input {
  border: 1px solid #d9d9d9;
  border-radius: 7px;
  padding: 12px;
  outline: none;
}

.cwt1 tr td:nth-child(2) input {
  width: 150px;
}
.cwt1 tr td:nth-child(3) input {
  width: 350px;
}

.cwt1 tr td:nth-child(4) input {
  width: 350px;
}

.cwt1 tr td:nth-child(5) input {
  width: 110px;
}

.crawlingTableWrapper {
  width: 100%;
  overflow-x: auto;
  display: block;
}

.crawlingTableWrapper .cwt2 {
  width: 100%;
}
.cwt2 th,
.cwt2 td {
  vertical-align: middle;
  word-break: break-word; /* 긴 단어도 줄바꿈 */
  max-width: 200px; /* 선택적: 최대 너비 제한 */
}

.cwt2 td a {
  color: #6666cc;
  line-height: 20px;
}
