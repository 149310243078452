@media (max-width: 768px) {
  aside.aside {
    display: none;
  }
  .banner {
    display: none;
  }

  .wrapper {
    width: 100%;
    padding: 0px 20px;
  }
  .trends {
    padding: 25px;
  }

  .index {
    display: block;
  }
  .trends h1 {
    font-size: 22px;
  }

  .trends__table {
    margin-top: 15px;
    margin-bottom: 5px;
    border-top: 1px solid #d9d9d9;
    table-layout: auto;
  }

  .trends__table thead {
    display: none;
  }

  .trends__table th:nth-child(3),
  .trends__table td:nth-child(3) {
    width: 25%;
  }

  .trends__table tbody {
    border: none;
  }

  .trends__table td {
    font-size: 15px;
  }

  .trends__table tbody tr:nth-child(1) td:last-child,
  .trends__table tbody tr:nth-child(2) td:last-child,
  .trends__table tbody tr:nth-child(3) td:last-child {
    font-size: 13px;
  }

  .trends__table td.rate-new {
    font-size: 12px;
  }
  .trends__table td {
    padding: 15px 0px;
  }

  .trends__table tbody::before {
    content: "";
    display: table-row;
    height: 15px;
  }

  .mLogo {
    display: flex;
  }

  body {
    padding-bottom: 135px;
  }

  .mFooter {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: white;
    width: 100%;
    height: 80px;
    z-index: 1000;
    /* border-top-left-radius: 30px;
    border-top-right-radius: 30px; */
    background-image: url("../images/shape.png");
    background-position: bottom;
    background-repeat: no-repeat;
  }

  .mFooter div:nth-child(1),
  .mFooter div:nth-child(3) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
  }

  .mFooter div:nth-child(1) span,
  .mFooter div:nth-child(3) span {
    font-size: 12px;
    font-family: "GmarketSans-Medium";
    color: #336666;
    position: absolute;
    left: 50%;
    top: 62px;
    transform: translateX(-50%);
  }

  .mFooter .mFooter__home {
    position: relative;
  }

  .mFooter .mFooter__home img {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
  }

  .autoPost {
    display: none;
  }

  .mHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 25px;
  }

  .mHeader h2 {
    font-family: "WantedSans-SemiBold";
    font-size: 16px;
  }

  .subPage {
    display: block;
  }

  .hidden {
    visibility: hidden;
  }

  .m-warning {
    display: block;
  }

  .m-warning img {
    width: 100%;
    height: 100%;
  }

  .boards {
    padding: 15px;
  }

  .boards select {
    display: none;
  }

  .boards .searchForm input {
    border: 1px solid #d9d9d9;
    width: 208px;
  }

  .boards .searchForm {
    background-color: white;
    padding: 10px 0px;
    margin-bottom: 12px;
  }

  .boards .searchForm .searchBtn {
    width: 80px;
  }

  .boards__table th:first-child {
    display: none;
  }

  .boards__table tr td:first-child {
    display: none;
  }

  .boards__table td a {
    max-width: 120px;
    display: inline-block;
  }

  .boards__table th {
    font-size: 13.6px;
  }

  .boards__table td {
    font-size: 13px;
  }

  .postWriteBtn {
    font-size: 13px;
    background-color: white;
    color: rgb(8, 109, 205);
    border: 2px solid rgb(8, 109, 205);
  }

  .pagination-page {
    width: 25px;
    height: 25px;
    font-size: 13px;
  }

  .pagination-page:not(:last-child) {
    margin-right: 8px;
  }

  .postView {
    padding: 25px;
  }

  .post__header {
    flex-direction: column;
    justify-content: center;
  }

  .post__header h1 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .post__header p {
    font-size: 13px;
  }

  .post__header {
    padding: 15px 0px;
  }

  .post__content {
    padding: 25px 0px;
    line-height: 23px;
    min-height: 235px;
  }

  .post__content p {
    font-size: 14px;
  }

  .backButton {
    font-size: 14px;
    padding: 15px 17px;
  }

  .post__actionBox {
    margin-top: 20px;
    width: 140px;
  }
  .table__anotherPost td {
    font-size: 13px;
  }

  .table__anotherPost img {
    display: none;
  }

  .table__anotherPost tr td:nth-child(2) {
    padding-left: 25px;
  }

  .postWrite {
    padding: 25px;
  }

  .postWrite--title {
    font-size: 18px;
    padding-bottom: 13px;
  }

  .writeForm .form__group {
    padding-left: 0px;
    flex-direction: column;
    align-items: flex-start;
  }
  .form__group .caption {
    font-size: 15px;
    position: static;
  }

  .form__group input,
  textarea {
    padding: 10px 15px;
    font-size: 14px;
  }

  .writeForm {
    margin-top: 15px;
  }
  .form__group textarea {
    min-height: 200px;
  }

  .form__group .file-selectBtn {
    font-size: 14px;
    height: 38px;
  }
  .postButton {
    font-size: 14px;
    padding: 12px 20px;
  }

  .gradient-wrapper button {
    font-size: 13px;
  }
  .boards .searchForm .searchBtn img {
    width: 17px;
  }

  .searchForm input {
    font-size: 13px;
  }

  .mTrendsAlert {
    display: flex;
  }

  .crawling-box {
    padding: 25px;
  }

  .searchForm {
    flex-wrap: wrap;
    row-gap: 10px;
    padding: 25px 10px;
  }
  .searchForm select {
    width: 90px;
    font-size: 13px;
  }

  .searchForm input {
    width: 140px;
    margin-right: 0px;
  }

  .searchForm input[name="urls"] {
    width: 58%;
    margin-right: 8px;
  }

  .crawling-box .gradient-wrapper button {
    width: 100%;
    padding: 12px 10px;
    height: 45px;
  }

  .crawling-box .gradient-wrapper button span {
    font-size: 13px;
  }

  .highlight-box ul li {
    font-size: 13px;
  }

  .crawling-table.cwt1 {
    display: none;
  }

  .m-crawling-table {
    display: block;
  }

  .crawling-table-wrapper {
    overflow-x: auto;
  }

  .cwt2 th {
    font-size: 13px;
  }

  .cwt2 th,
  .cwt2 td {
    vertical-align: middle;
    word-break: keep-all;
    white-space: nowrap;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .crawling-table thead th {
    padding: 15px;
  }
}
