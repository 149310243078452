.navBar {
  display: flex;
  flex-direction: column;
  width: 230px;
  height: 100vh;
  background-color: #ffffff;
  padding: 40px;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
  border-right: 1px solid #e0e0e0;
}

.hidden {
  display: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 900;
}

.logoImage {
  width: 150px;
  margin-bottom: 20px;
}

.kakaoBox {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 8px;
}

.userBox {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px 20px 15px 20px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.userName {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 12px;
}

.userLevel,
.balance,
.expiryDate {
  font-size: 14px;
  color: #666;
  margin: 5px 0;
}

.menuButton {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #007bff;
  color: #ffffff;
  font-size: 18px;
  border: none;
  border-radius: 10px;
  padding: 12px 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  z-index: 1000;
  transition: background-color 0.3s, transform 0.2s;
}

.menuButton:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.mobileMenu {
  position: fixed;
  top: 0;
  right: 0;
  width: 70%;
  height: 100%;
  background-color: #f9f9f9;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  animation: slideIn 0.3s ease-in-out;
}

.mobileMenu.slideOut {
  animation: slideOut 0.3s ease-in-out;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.mainLabel {
  color: black;
  font-size: 18px;
  padding: 5px 8px;
  font-weight: 750;
}

.navItems {
  list-style: none;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
}

.navItems li {
  font-size: 16px;
  font-weight: 500;
  margin: 10px 0;
}

.navItems a {
  text-decoration: none;
  color: #333333;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.navItems a:hover {
  color: #007bff;
}

.navItems a.activeStyle {
  font-weight: 800;
  color: #007bff;
}

@media (max-width: 768px) {
  .navBar {
    display: none;
  }
}
