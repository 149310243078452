.container {
  margin: 0 auto;
  padding: 50px;
  display: grid;
  grid-template-columns: 0.9fr 2fr;
  column-gap: 20px;
}

h1 {
  font-family: "WantedSans-Bold";
  font-size: 24px;
  margin-bottom: 21px !important;
}

.section {
  margin-bottom: 40px;
}

.leftPane {
  flex: 2;
}

.rightPane {
  flex: 1;
  border: 1px solid #d9d9d9;
  border-radius: 18px;
  padding: 40px 40px;
  position: relative;
}

.rightPane h1 {
  font-size: 36px;
  padding-bottom: 33px;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 30px;
}

.row {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}

.input {
  flex: 1;
  border: 1px solid #d9d9d9;
  text-align: center;
  padding: 15px 0px;
  width: 395px;
  border-radius: 8px;
  margin-right: 10px;
  height: 45px;
  font-size: 17px;
  color: #666666;
}

.activeInput {
  border: 2px solid blue;
}

.button {
  font-size: 17px;
  color: #666666;
  background-color: #f3f3f3;
  border: none;
  width: 160px;
  height: 45px;
  border-radius: 7px;
  padding: 0;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-weight: 700;
}

.button:hover {
  color: #0066cc;
  font-weight: 700;
  border: 1.5px solid #0066cc;
  background-color: #eaf5fe;
}

.activeButton {
  color: #0066cc;
  font-weight: 700;
  border: 1.5px solid #0066cc;
  background-color: #eaf5fe;
}

.option {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: background-color 0.4s ease;
  border-radius: 30px;
  border: 1.5px solid #0a6dcd;
  font-weight: 500;
}

.slider:before {
  position: absolute;
  content: "꺼짐";
  color: white;
  font-size: 14px;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  transition: left 0.4s ease, content 0.4s ease, color 0.4s ease;
}

.slider:after {
  position: absolute;
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: transparent;
  height: 24px;
  width: 24px;
  left: 1px;
  bottom: 1.5px;
  background-color: #0a6dcd;
  border-radius: 50%;
  transition: transform 0.4s ease, color 0.4s ease;
}

input:checked + .slider {
  background-color: white;
}

input:checked + .slider:before {
  content: "켜짐";
  left: 10px;
  color: #0a6dcd;
}

input:checked + .slider:after {
  transform: translateX(40px);
  color: transparent;
  background-image: url("../../styles/images/checkImg.png");
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
}

input:not(:checked) + .slider:before {
  content: "꺼짐";
  left: 34px;
  color: #0a6dcd;
}

input:not(:checked) + .slider:after {
  transform: translateX(0);
  color: transparent;
}

.submitButton {
  width: 138px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0px;
  color: white;
  font-family: "WantedSans-Medium";
  font-size: 16px;
  border: none;
  border-radius: 8px;
  background: linear-gradient(to right, #cc3399, #3399ff);
  cursor: pointer;
}

.submitButton:hover {
  background-color: #218838;
}

.output {
  max-width: 802px;
  min-height: 150px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #ffffff;
  margin-bottom: 20px;
  overflow-y: auto;
}

.jsonOutput {
  font-size: 14px;
  line-height: 1.5;
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  border: 1px solid #ddd;
  max-height: 300px;
  overflow-y: auto;
}

.imagePreviewContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 30px;
}

.imagePreview {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px 0;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.imagePreview img {
  max-width: 100%;
  max-height: 120px;
  border-radius: 5px;
  object-fit: contain;
  flex: 1;
}

.deleteButton {
  font-size: 15px;
  margin-right: 15px;
  padding: 5px 10px;
  background-color: #ff4d4f;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.deleteButton:hover {
  background-color: #d9363e;
}

.imagePreview:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.option span {
  font-size: 17px;
  color: #666666;
  font-weight: 700;
}

.gridSection {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
}

.Imgsection {
  display: flex;
  padding: 10px 16px;
  background-color: #eaf5fe;
  position: relative;
  text-align: center;
  border-radius: 9px;
  color: #0066cc;
  font-size: 17px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.customFileInput {
  display: none;
}

.textCenter {
  flex: 1;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
}

.customFileLabel {
  margin-left: auto;
  padding: 7px 15px;
  text-align: center;
  display: inline-block;
  border: 1px solid #0a6dcd;
  background-color: white;
  color: #0a6dcd;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.customFileLabel:hover {
  color: white;
  background-color: #0a6dcd;
}

.buttonIcon {
  margin-right: 10px;
}
