.trends {
  height: 100%;
  padding: 0px 80px;
}

.trends h1 {
  font-family: "GmarketSans-Bold";
  font-size: 36px;

  background: linear-gradient(to right, #cc3399, #3399ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;

  padding-top: 5px;
  line-height: 1.2;
  display: inline-block;
}

.trends__content {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.trends__table {
  width: 100%;
  table-layout: fixed;
  margin-top: 40px;
}

.trends__table tbody {
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.08);
}

.trends__table thead {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.trends__table td {
  padding: 20px 0px;
  font-family: "WantedSans-Regular";
  font-size: 18px;
  color: #666666;
  font-weight: bold;
}

.trends__table th,
.trends__table td {
  text-align: center;
  vertical-align: middle;
}

.trends__table th:nth-child(1),
.trends__table td:nth-child(1) {
  width: 15%;
}

.trends__table th:nth-child(2),
.trends__table td:nth-child(2) {
  width: 45%;
}

.trends__table th:nth-child(3),
.trends__table td:nth-child(3) {
  width: 40%;
}

.trends__table th img {
  vertical-align: middle;
  margin-right: 5px;
}

.trends__table th {
  font-family: "WantedSans-Bold";
  font-size: 16px;
  color: #999999;
  padding: 16px 0px;
}

.trends__table tbody tr:nth-child(1) td:first-child,
.trends__table tbody tr:nth-child(2) td:first-child,
.trends__table tbody tr:nth-child(3) td:first-child {
  background-image: url(../images/rate-high.png);
  background-repeat: no-repeat;
  color: white;
}

.trends__table tbody tr td:first-child {
  position: relative;
  background-position: center;
  background-image: url(../images/rate-normal.png);
  background-repeat: no-repeat;
  color: #3399ff;
}

.trends__table .rate-up {
  color: #ff6666;
}

.trends__table .rate-down {
  color: #0066cc;
}

.trends__table .rate-new {
  color: #9933cc;
}

.trends__table tbody tr:nth-child(1) td:last-child,
.trends__table tbody tr:nth-child(2) td:last-child,
.trends__table tbody tr:nth-child(3) td:last-child {
  font-size: 16px;
}

.triangle {
  margin-right: 6px;
}

.banner {
  display: flex;
  flex-direction: column;
}

.banner a {
  flex: 1;
  display: block;
}

.banner a:not(:last-child) {
  margin-bottom: 37px;
}
