.container {
  display: flex;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.imageSection {
  flex: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.formSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.basicSection {
  width: 50%;
  align-items: flex-start;
}

h1 {
  font-size: 2rem;
  margin-bottom: 0 !important;
}

p {
  color: #666;
  margin-top: 0 !important;
  margin-bottom: 3rem;
  white-space: nowrap;
}

.inputGroup {
  margin-bottom: 1rem;
  width: 100%;
}

.inputGroup input {
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.checkboxGroup {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}

.checkboxGroup input {
  margin-right: 0.5rem;
}

.submitButton {
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 10px;
}

.submitButton:hover {
  background-color: #0056b3;
}

.forgotPassword a {
  color: #007bff;
  text-decoration: none;
  margin-top: 1rem;
}

.forgotPassword a:hover {
  text-decoration: underline;
}

.errorMessage {
  color: red;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  white-space: nowrap;
}

.successMessage {
  color: green;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .imageSection {
    display: none;
  }

  .formSection {
    border-radius: 0;
    width: 100%;
    box-shadow: none;
  }
}
